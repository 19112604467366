import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MsalModule, MsalService, MsalGuard, MsalInterceptor, MsalBroadcastService, MsalRedirectComponent, MsalGuardConfiguration, MsalInterceptorConfiguration, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from "@azure/msal-angular";
import { PublicClientApplication, InteractionType, BrowserCacheLocation, IPublicClientApplication, LogLevel } from "@azure/msal-browser";
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LookupsComponent } from './lookups/lookups.component';
import { LoginFailedComponent } from './login-failed.component';
import { HomeComponent } from './home/home.component';
import { MatToolbarModule} from '@angular/material/toolbar';
import { MatTabsModule} from '@angular/material/tabs';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog'
import {MatIconModule} from '@angular/material/icon'
import {MatCardModule} from '@angular/material/card'
import {MatSelectModule} from '@angular/material/select'
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatProgressBarModule} from '@angular/material/progress-bar'
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DialogBoxComponent } from './dialog-box/dialog-box.component';
import { LookupPanelComponent } from './lookup-panel/lookup-panel.component';
import { MatInputModule } from '@angular/material/input';
import { NewTabComponent } from './new-tab/new-tab.component';
import { UsersComponent } from './users/users.component';
import { UserPanelComponent } from './user-panel/user-panel.component';
import { ApiPrefixInterceptor } from './http/api-prefix.interceptor';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { SearchFilterPipe } from './pipes/search-filter.pipe';
import { ConfigService } from './services/config.service';
import { HttpBackendClient } from './services/httpBackendClient';
import { MatMenuModule} from '@angular/material/menu';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardPanelComponent } from './dashboard-panel/dashboard-panel.component';
import { SecurityTabComponent } from './security-tab/security-tab.component';
import { SecurityPanelComponent } from './security-panel/security-panel.component';
import { ChildFormComponent } from './child-form/child-form.component';
import { RuleUserComponent } from './rule-user/rule-user.component'

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;


export function loggerCallback(logLevel: LogLevel, message: string) {
  // console.log(message); // LOGGER FOR MSAL
}


export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: ConfigService.settings.auth,
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage, // >>>> Update
      storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}

  export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
      console.log()
      const protectedResourceMap = new Map<string, Array<string>>(ConfigService.settings.protectedResourceMap);
  
      return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap,
      };
    }

  export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
      interactionType: InteractionType.Redirect,
      authRequest: {
        scopes: ["user.read"],
      },
      loginFailedRoute: "/login-failed",
    };
  }


export function initializeApp(configService: ConfigService) {
  return () => configService.getsettings();
}


@NgModule({
  declarations: [
    AppComponent,
    LookupsComponent,
    LoginFailedComponent,
    HomeComponent,
    LookupPanelComponent,
    DialogBoxComponent,
    NewTabComponent,
    UsersComponent,
    UserPanelComponent,
    SearchFilterPipe,
    DashboardComponent,
    DashboardPanelComponent,
    SecurityTabComponent,
    SecurityPanelComponent,
    ChildFormComponent,
    RuleUserComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule, FormsModule,
    MatToolbarModule,
    MatTabsModule,
    MatTableModule,
    MatSortModule,
    MatCheckboxModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatCardModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MsalModule,
    MatMenuModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [ConfigService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiPrefixInterceptor,
      multi: true
  },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
  },

  {
    provide: MSAL_INSTANCE,
    useFactory: MSALInstanceFactory,
  },

  {
    provide: MSAL_GUARD_CONFIG,
    useFactory: MSALGuardConfigFactory,
  },
  {
    provide: MSAL_INTERCEPTOR_CONFIG,
    useFactory: MSALInterceptorConfigFactory,
  },
  MsalGuard,
  MsalService,
  HttpBackendClient,
  MsalBroadcastService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }




// import { NgModule } from '@angular/core';
// import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
// import { AppComponent } from './app.component';


// @NgModule({
//   imports: [
//     MsalModule.forRoot(new PublicClientApplication({ // MSAL Configuration
//       auth: {
//         clientId: "Your client ID",
//         authority: "Your authority",
//         redirectUri: "Your redirect Uri",
//       },
//       cache: {
//         cacheLocation: BrowserCacheLocation.LocalStorage,
//         storeAuthStateInCookie: true, // set to true for IE 11
//       },
//       system: {
//         loggerOptions: {
//           loggerCallback: () => { },
//           piiLoggingEnabled: false
//         }
//       }
//     }), {
//       interactionType: InteractionType.Redirect, // MSAL Guard Configuration
//     }, {
//       interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
//     })
//   ],
//   providers: [
//     {
//       provide: HTTP_INTERCEPTORS,
//       useClass: MsalInterceptor,
//       multi: true
//     },
//     MsalService,
//     MsalGuard,
//     MsalBroadcastService
//   ],
//   bootstrap: [AppComponent, MsalRedirectComponent]
// })
// export class AppModule { }

