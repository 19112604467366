
import { v4 as uuid } from "uuid";
import { Attribute } from "./attribute";
export class Report {
    Id: Attribute = {ATTRIBUTE_NAME:"Id",ATTRIBUTE_PROMPT:"Id",ATTRIBUTE_REQUIRED:true, ATTRIBUTE_VALUE:uuid(),ATTRIBUTE_TYPE:''}
    type: Attribute = {ATTRIBUTE_NAME:"type",ATTRIBUTE_PROMPT:"type",ATTRIBUTE_REQUIRED:true, ATTRIBUTE_VALUE:'report', ATTRIBUTE_TYPE:'hidden'}
    aggregate: Attribute = {ATTRIBUTE_NAME:"aggregate",ATTRIBUTE_PROMPT:"aggregate",ATTRIBUTE_REQUIRED:true, ATTRIBUTE_VALUE:'SEARS/Report', ATTRIBUTE_TYPE:'hidden'}
    REPORT_TITLE: Attribute = {ATTRIBUTE_NAME:"REPORT_TITLE",ATTRIBUTE_PROMPT:"Report Title",ATTRIBUTE_REQUIRED:true, ATTRIBUTE_VALUE:''}
    REPORT_APP: Attribute = {ATTRIBUTE_NAME:"REPORT_APP",ATTRIBUTE_PROMPT:"Report App",ATTRIBUTE_REQUIRED:false, ATTRIBUTE_VALUE:''}
    REPORT_KEY: Attribute = {ATTRIBUTE_NAME:"REPORT_KEY",ATTRIBUTE_PROMPT:"Report Key",ATTRIBUTE_REQUIRED:false, ATTRIBUTE_VALUE:''}
}
