<mat-toolbar role="toolbar" color="primary" class="mat-elevation-z4">
    <a class="title" href="/" matTooltip="Redirect to home">
      {{ title }}
    </a>
  
    <div class="toolbar-spacer"></div>
  
    <a button mat-button *ngIf="!loginDisplay" (click)="loginRedirect()" matTooltip="Login to Account">Login</a>
  
    <a mat-button color="" *ngIf="loginDisplay" (click)="logout()" matTooltip="Logout from Account">Logout</a>
  
    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="icon-button with a menu" matTooltip="App Info">
      <mat-icon>more_vert</mat-icon>
    </button>
  
    <mat-menu #menu="matMenu">
      <button mat-menu-item>
        <mat-icon> account_circle </mat-icon>
        <span *ngIf="userAccount"> {{ userAccount.name }}</span>
      </button>
      
      <button mat-menu-item>
        <mat-icon> phone_iphone </mat-icon>
        <span> Version {{ appDetails.version }}</span>
      </button>
  
      <button mat-menu-item>
        <mat-icon> cloud_upload </mat-icon>
        <span translate> Release {{ appDetails.release }} </span>
      </button>
    </mat-menu>
  
  </mat-toolbar>
  
  
  <div class="container">
    <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
    <router-outlet *ngIf="!isIframe"></router-outlet>
  </div>