<mat-card class=card *ngIf="formCurrent">
    <form [formGroup]="formCurrent" class="column" *ngIf="formCurrent" class=column>
        <div *ngFor="let attribute of obj.detail">
            <mat-form-field *ngIf="attribute.ATTRIBUTE_TYPE!='hidden'" appearance="outline">
                <mat-label>{{attribute.ATTRIBUTE_PROMPT}}</mat-label>
                <input matInput formControlName={{attribute.ATTRIBUTE_NAME}} type={{attribute.ATTRIBUTE_TYPE}}>

            </mat-form-field>
        </div>
        <!-- <div *ngIf=data.CHILDREN>

            <div *ngFor="let childForm of datal.CHILDREN">
                <app-child-form [data]= childForm></app-child-form>

            </div>
        </div> -->
        <!-- <button mat-raised-button color=primary [disabled]="!formCurrent.valid" (click)=addChild()>AddChild</button> -->

        <div *ngIf="formCurrent">
            <button mat-raised-button type=submit color=primary [disabled]="!formCurrent.dirty||readonly"
                (click)=(formCurrent.valid?raise():notValid())>Save</button>

        </div>
    </form>
</mat-card>

