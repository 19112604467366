import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from '../services/config.service';
import { QueryService } from '../services/query.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-lookups',
  templateUrl: './lookups.component.html',
  styleUrls: ['./lookups.component.scss']
})
export class LookupsComponent implements OnInit {

  lookups:any
 

  @Input() lookupname:string

  constructor(private userService: UserService, private queryService: QueryService){}
  ngOnInit() {
    console.log("lookupname:"+this.lookupname)
    this.userService.getUserInfo().subscribe(res => console.log(res))
    this.queryService.getBlob(ConfigService.settings.account,"sselookups-cv",this.lookupname).subscribe(res => {this.lookups=res.data; console.log(this.lookupname,this.lookups);})
  }


}
