import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Rule, Security } from '../security';
import { CommandService } from '../services/command.service';
import { ConfigService } from '../services/config.service';
import { EventService } from '../services/event.service';
import { QueryService } from '../services/query.service';
import { User } from '../user';


@Component({
  selector: 'app-child-form',
  templateUrl: './child-form.component.html',
  styleUrls: ['./child-form.component.scss']
})
export class ChildFormComponent implements OnInit {

  
  @Input() data
  @Input() subject
  

  lastView: Security
  formCurrent: FormGroup;
  @Input() pathroot
  @Input() blankChild
  @Input() blank2Child
  @Input() childPath
  @Input() newChild
  childpathroot
  pathObj
  readonly: boolean = false
  obj
  @Output() eventRaised: EventEmitter<any> = new EventEmitter<any>();


  constructor(private commandService: CommandService, private eventService: EventService,
    private queryService: QueryService, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    console.log("Child Form subject:", this.subject)
    console.log("blank child", this.blankChild)
    console.log("child Path",this.childPath)
    console.log("newChild",this.newChild)
    console.log("data",this.data)
    console.log("blank2 child", this.blank2Child)
    this.fillForm()
  }

  fillForm(): void {
    this.lastView = this.data
    console.log("sercuritypanel:", this.data)

    this.pathroot = this.pathroot+".Method"
   this.pathObj = this.eventService.getPathObjdyn(this.data, this.data.Id, this.pathroot)
    console.log(" formFill this.pathObj:", this.pathObj)
    this.obj = this.eventService.populateFormdyn(new Rule, this.pathObj, this.data.Id)
    console.log("obj here:", this.obj)
    console.log("obj.form:", this.obj.form)
    this.formCurrent = this.formBuilder.group(this.obj.form)
    console.log("formCurrent:", this.formCurrent)
    console.log("details", this.obj.detail)
    //return this.formBuilder.group(formObj.form);

    this.childpathroot=this.pathObj.path+".user"
    console.log("this.childpathroot:"+this.childpathroot)
  }

  raise(): void {
    console.log("Raise")
    let eventBody = this.eventService.getEventBodydyn(this.formCurrent, this.pathObj)

    console.log("eventBody", eventBody)

    let type= this.obj.form.type[0]
    console.log("type", type)
    console.log("subject:"+this.subject+"."+ eventBody.Id)
    this.commandService.raise(this.subject+"."+ eventBody.Id,
      `${ConfigService.settings.projectCode}.${this.obj.form.aggregate[0]}.${this.obj.form.type[0]}.${this.pathObj.action}`,
      `events`,
      eventBody)
      .subscribe(data => {
        console.log("Raised:", data);
        if (!this.lastView) {
          this.lastView = data[0].data;
        } else {
          this.lastView = this.eventService.reducer(this.lastView, data[0]);
        }
        console.log("emit")
        this.eventRaised.emit(this.lastView);
        console.log("emited event")

      }
      )


  }
  notValid(): void {
    console.log("not valid")
  }

  addChild() : void {
    
      
    
    // console.log("child", this.sercuritypanel)
     if (!this.data.children) {
      this.data.children=[new User()]
     }
     else {
      this.data.children.push(new User())
     }
     console.log("children here",this.data)
     
 
   
 }
}

