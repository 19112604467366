import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatSort, MatSortModule} from '@angular/material/sort';
import { MatDialog} from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { DialogBoxComponent } from '../dialog-box/dialog-box.component';
import { Lookup } from "../lookup"
import { v4 as uuid } from "uuid";


@Component({
  selector: 'app-lookup-panel',
  templateUrl: './lookup-panel.component.html',
  styleUrls: ['./lookup-panel.component.scss']
})
export class LookupPanelComponent implements OnInit {

  displayedColumns: string[]=[]
  display:boolean=false;

  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;

  @Input() lookup:any
  @Input() lookupname:string
  selection = new SelectionModel<any>(true, []);
  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
    let blank=new Lookup()
   // console.log("lookup", this.lookup.LEVEL1_ATTRIBUTE1)
  //   for (let attribute of Object.entries(blank) ) {
  //     this.displayedColumns.push(attribute[0])
  //     console.log(attribute)
  // }
  // this.displayedColumns.push('LEVEL0_ATTRIBUTE0')
  // this.displayedColumns.push('LEVEL1_ATTRIBUTE1')
  this.displayedColumns.push('LEVEL2_ATTRIBUTE2')
  this.displayedColumns.push('LEVEL2_ATTRIBUTE3')
  this.displayedColumns.push('LEVEL2_ATTRIBUTE4')
  this.displayedColumns.push('LEVEL2_ATTRIBUTE5')
  this.displayedColumns.push('LEVEL2_ATTRIBUTE6')
  this.displayedColumns.push('LEVEL2_ATTRIBUTE7')
  this.displayedColumns.push('LEVEL2_ATTRIBUTE8')
  this.displayedColumns.push('LEVEL2_ATTRIBUTE9')

  this.displayedColumns.push('Actions')

  //console.log("cols",this.displayedColumns )
    
    this.dataSource = new MatTableDataSource(this.lookup.LEVEL2);
    this.dataSource.sort = this.sort;
  //  console.log("ds:", this.dataSource)
    this.display=true;
  }

  addrow(){
    console.log("addrow")
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  openDialog(action,instance, cols) {
    let obj : any = {};
    if(!instance) {
      console.log("add row")
      instance=new Lookup()
      instance.Id=uuid()
    }
    obj.action = action;
    obj.class = new Lookup();
   
    //console.log("cols:", cols)
   
    obj.displayColumns=[...cols];
    obj.displayColumns.pop()
    console.log("this.lookupname:"+this.lookupname)
    obj.pathroot = "data:"+this.lookupname+".LEVEL1:"+this.lookup.Id+".LEVEL2";
    obj.lastView = this.lookup.LEVEL2;
    obj.instance =instance;
    //obj.tab2 = this.tab2;
    obj.project="she";
    const dialogRef = this.dialog.open(DialogBoxComponent, {
      width: '1800px',
      data:obj
    });
 
    dialogRef.afterClosed().subscribe(result => {
      console.log("returning:", result.rows)
      //this.lookup={}
      console.log("lokkup",this.lookup.LEVEL2)
      this.dataSource = new MatTableDataSource(this.lookup.LEVEL2);
      //this.lastView=result.lastView;
      //this.createTable() 

    });
  }

}
