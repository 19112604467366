<mat-card class=card>
    <mat-progress-bar *ngIf="working" mode="indeterminate" color="primary"></mat-progress-bar>

    <div>
        <form [formGroup]="formCurrent" class="column" *ngIf="formCurrent" class=column>
            <mat-form-field appearance="outline">
                <mat-label>Email</mat-label>
                <input appearance="outline" matInput type=email placeholder="Email" formControlName="USER_EMAIL">
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>First Name</mat-label>
                <input appearance="outline" matInput type=text placeholder="First Name" formControlName="USER_FIRST_NAME">
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Last Name</mat-label>
                <input appearance="outline" matInput type=text placeholder="Last Name" formControlName="USER_LAST_NAME">
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Supervisor</mat-label>
                <input appearance="outline" matInput type=text placeholder="Supervisor" formControlName="USER_SUPERVISOR_EMAIL">
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Location</mat-label>
                <input appearance="outline" matInput type=text placeholder="Location" formControlName="USER_LOCATION">
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Cost Centre</mat-label>
                <input matInput formControlName="USER_ORGANIZATION_NAME"  />
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Directorate</mat-label>
                <input matInput formControlName="USER_DIRECTORATE"  />
            </mat-form-field>
            <mat-form-field *ngIf="this.formCurrent.get('USER_REPORTING_LEVEL_1').value" appearance="outline" >
                <mat-label>Reporting Level 1</mat-label>
                <input matInput formControlName="USER_REPORTING_LEVEL_1"  />
            </mat-form-field >
            <mat-form-field *ngIf="this.formCurrent.get('USER_REPORTING_LEVEL_2').value" appearance="outline">
                <mat-label>Reporting Level 2</mat-label>
                <input matInput formControlName="USER_REPORTING_LEVEL_2"  />
            </mat-form-field>
            <mat-form-field *ngIf="this.formCurrent.get('USER_REPORTING_LEVEL_3').value" appearance="outline">
                <mat-label>Reporting Level 3</mat-label>
                <input matInput formControlName="USER_REPORTING_LEVEL_3"  />
            </mat-form-field>
            <mat-form-field *ngIf="this.formCurrent.get('USER_REPORTING_LEVEL_4').value" appearance="outline">
                <mat-label>Reporting Level 4</mat-label>
                <input matInput formControlName="USER_REPORTING_LEVEL_4"  />
            </mat-form-field>
            <mat-form-field *ngIf="this.formCurrent.get('USER_REPORTING_LEVEL_5').value" appearance="outline">
                <mat-label>Reporting Level 5</mat-label>
                <input matInput formControlName="USER_REPORTING_LEVEL_5"  />
            </mat-form-field>
            <mat-form-field *ngIf="this.formCurrent.get('USER_REPORTING_LEVEL_6').value" appearance="outline">
                <mat-label>Reporting Level 6</mat-label>
                <input matInput formControlName="USER_REPORTING_LEVEL_6"  />
            </mat-form-field>
            {{(this.formCurrent.get('USER_REPORTING_LEVEL_7').value)}}
            <mat-form-field *ngIf="this.formCurrent.get('USER_REPORTING_LEVEL_7').value" appearance="outline">
                <mat-label>Reporting Level 7</mat-label>
                <input matInput formControlName="USER_REPORTING_LEVEL_7"  />
            </mat-form-field>
            <mat-checkbox style="margin-right:5px; color:grey" formControlName="USER_ADMINISTRATOR">Administrator</mat-checkbox>
            <mat-checkbox style="margin-right:5px; color:grey" formControlName="USER_BU_ADMINISTRATOR">Business Unit Administrator</mat-checkbox>
            <mat-checkbox style="margin-right:5px; color:grey" formControlName="USER_GLOBAL_ADMINISTRATOR">Global Administrator</mat-checkbox>
        </form>
        <div class=buttons>
            <button mat-raised-button color=primary (click)=raise()>Update</button>
            <button mat-raised-button color=primary
                (click)="flag=!flag;fillForm(flag)">{{(flag?'Decrypt':'Encypt')}}</button>
        </div>
    </div>
</mat-card>
