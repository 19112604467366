<mat-tab-group *ngIf=lookups>
    <mat-tab *ngFor="let lookup of lookups.LEVEL1" [label]=lookup.LEVEL1_ATTRIBUTE1>
      <app-lookup-panel [lookup]="lookup" [lookupname]=lookupname></app-lookup-panel>
    </mat-tab>
    <mat-tab>          
        <ng-template mat-tab-label>
        <mat-icon>add</mat-icon>
      </ng-template>
      <app-new-tab [tab]=lookups [lookupname]=lookupname class="lookupLevel1"></app-new-tab>
    </mat-tab>
</mat-tab-group>
