<!-- <button mat-button color=primary  (click)=addrow()>Add rows</button> -->
<button mat-button color=primary (click)="openDialog('Add',undefined,displayedColumns )">Add row</button>

<table *ngIf=display mat-table [dataSource]="dataSource" matSort class="lookup-table">
    
    <ng-container matColumnDef="LEVEL2_ATTRIBUTE2">
        <th mat-header-cell *matHeaderCellDef mat-sort-header id="LEVEL2_ATTRIBUTE2"> LEVEL2_ATTRIBUTE2  </th>
        <td mat-cell *matCellDef="let row">{{row.LEVEL2_ATTRIBUTE2}}</td>
    </ng-container>

    <ng-container matColumnDef="LEVEL2_ATTRIBUTE3">
        <th mat-header-cell *matHeaderCellDef mat-sort-header id="LEVEL2_ATTRIBUTE3"> LEVEL2_ATTRIBUTE3 </th>
        <td mat-cell *matCellDef="let row">{{row.LEVEL2_ATTRIBUTE3}}</td>
    </ng-container>
    <ng-container matColumnDef="LEVEL2_ATTRIBUTE4">
        <th mat-header-cell *matHeaderCellDef mat-sort-header id="LEVEL2_ATTRIBUTE4"> LEVEL2_ATTRIBUTE4 </th>
        <td mat-cell *matCellDef="let row">{{row.LEVEL2_ATTRIBUTE4}}</td>
    </ng-container>
    <ng-container matColumnDef="LEVEL2_ATTRIBUTE5">
        <th mat-header-cell *matHeaderCellDef mat-sort-header id="LEVEL2_ATTRIBUTE5"> LEVEL2_ATTRIBUTE5 </th>
        <td mat-cell *matCellDef="let row">{{row.LEVEL2_ATTRIBUTE5}}</td>
    </ng-container>
    <ng-container matColumnDef="LEVEL2_ATTRIBUTE6">
        <th mat-header-cell *matHeaderCellDef mat-sort-header id="LEVEL2_ATTRIBUTE6"> LEVEL2_ATTRIBUTE6 </th>
        <td mat-cell *matCellDef="let row">{{row.LEVEL2_ATTRIBUTE6}}</td>
    </ng-container>
    <ng-container matColumnDef="LEVEL2_ATTRIBUTE7">
        <th mat-header-cell *matHeaderCellDef mat-sort-header id="LEVEL2_ATTRIBUTE7"> LEVEL2_ATTRIBUTE7 </th>
        <td mat-cell *matCellDef="let row">{{row.LEVEL2_ATTRIBUTE7}}</td>
    </ng-container>
    <ng-container matColumnDef="LEVEL2_ATTRIBUTE8">
        <th mat-header-cell *matHeaderCellDef mat-sort-header id="LEVEL2_ATTRIBUTE8"> LEVEL2_ATTRIBUTE8 </th>
        <td mat-cell *matCellDef="let row">{{row.LEVEL2_ATTRIBUTE8}}</td>
    </ng-container>
    <ng-container matColumnDef="LEVEL2_ATTRIBUTE9">
        <th mat-header-cell *matHeaderCellDef mat-sort-header id="LEVEL2_ATTRIBUTE9"> LEVEL2_ATTRIBUTE9 </th>
        <td mat-cell *matCellDef="let row">{{row.LEVEL2_ATTRIBUTE9}}</td>
    </ng-container>

    <ng-container matColumnDef="Actions" class="tabcol">
        <th mat-header-cell *matHeaderCellDef mat-sort-header id="Actions"> Action </th>
         <td mat-cell *matCellDef="let row"> <button mat-button color=primary (click)="openDialog('Updated',row,displayedColumns )">Edit</button> </td>
  
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>




</table>