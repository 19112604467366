import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from  '../../environments/environment'
import { ConfigService } from '../services/config.service';


/**
 * Prefixes all requests not starting with `http[s]` with `environment.serverUrl`.
 */
@Injectable({
  providedIn: 'root',
})
export class ApiPrefixInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!/^(http|https):/i.test(request.url)) {
      switch (request.url) {
        case 'getAndBuildBlob':
        case 'ListBlobs':
        case 'getBlob':
        case 'getAlias':
        case 'getList':
        case 'raiseEvent':
          request = request.clone({
            url: `${ConfigService.settings.apim}/${ConfigService.settings.searsApi}/${request.url}`,
          });
          break;
      }
    }
    return next.handle(request);
  }
}
