import { Injectable } from '@angular/core';
import { HttpParams,HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class QueryService {

  constructor(private httpClient: HttpClient) {}

  getList(account, container, filter='') {


    let httpCall = {
      url: `getList`,
      options: {
        params: new HttpParams()
          .set("account", ConfigService.settings.account)
          .set("container", container)
          .set("filter",filter)

      },
    };

    return this.httpClient.get<any>(httpCall.url, httpCall.options).pipe(
      map((data) => {
        console.log("data:", data)
        return data;
      })
    );
  }

  getBlob(account, container, blob) {


    let httpCall = {
      url: `getBlob`,
      options: {
        params: new HttpParams()
          .set("account", ConfigService.settings.account)
          .set("container", container)
          .set("blob", blob)

      },
    };

    return this.httpClient.get<any>(httpCall.url, httpCall.options).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getAndBuild(account, container, blob, secure) {

    
    let httpCall = {
      url: `getAndBuildBlob`,
      options: {
        params: new HttpParams()
          .set("account", ConfigService.settings.account)
          .set("container", container)
          .set("blob", blob)
          /* .set("skipdecrypt",secure) */

      },
    };

    return this.httpClient.get<any>(httpCall.url, httpCall.options).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getAlias(account, container, blob, secure) {

    console.log("here get alias "+secure)
    let httpCall = {
      url: `getAlias`,
      options: {
        params: new HttpParams()
          .set("account", ConfigService.settings.account)
          .set("container", container)
          .set("blob", blob)
          .set("skipdecrypt",(secure?"true":"false"))

      },
    };

    return this.httpClient.get<any>(httpCall.url, httpCall.options).pipe(
      map((data) => {
        return data;
      })
    );
  }

}
