import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class UserService {
  constructor(private http: HttpClient) { }

  getUserInfo = (): Observable<any> => {
    return this.http.get('https://graph.microsoft.com/v1.0/me');
  };

}
