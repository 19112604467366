<mat-card >
<form [formGroup]="formCurrent" class="column" *ngIf="formCurrent">
    

     <div *ngFor="let attribute of displayFields" >
        {{attribute}}
        <mat-form-field>
            <input matInput type=text [placeholder]=attribute [formControlName]=attribute autocomplete="off">
        </mat-form-field>

        
    </div>
        
    
  
</form>
<mat-card-actions class=buttons>

    <button *ngIf="pathObj.action==='Deleted'" mat-icon-button type="submit" [disabled]="!formCurrent.valid"
        color="warn" (click)="raise()">
        <mat-icon>cancel</mat-icon>
    </button>
    <button *ngIf="pathObj.action!='Deleted'" mat-icon-button type="submit" 
        color="primary" (click)="close()">
        <mat-icon>cancel</mat-icon>
    </button>
    <button *ngIf="pathObj.action!='Deleted'" mat-icon-button type="submit" [disabled]="!formCurrent.dirty"
        color="primary" (click)="raise()">
        <mat-icon>check</mat-icon>
    </button>
</mat-card-actions>
</mat-card>
