import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Rule, Security,User } from '../security';
import { CommandService } from '../services/command.service';
import { ConfigService } from '../services/config.service';
import { EventService } from '../services/event.service';
import { QueryService } from '../services/query.service';
;

@Component({
  selector: 'app-rule-user',
  templateUrl: './rule-user.component.html',
  styleUrls: ['./rule-user.component.scss']
})
export class RuleUserComponent implements OnInit {

  @Input() data2
  @Input() subject
  

  lastView: Security
  formCurrent: FormGroup;
  @Input() pathroot
  @Input() blankChild
  @Input() childPath
  @Input() newChild
  pathObj
  readonly: boolean = false
  obj
  @Output() eventRaised: EventEmitter<any> = new EventEmitter<any>();


  constructor(private commandService: CommandService, private eventService: EventService,
    private queryService: QueryService, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    console.log("Child Form subject:", this.subject)
    console.log("blank child", this.blankChild)
    console.log("data",this.data2)

    console.log("pathroot:"+this.pathroot)
    this.fillForm()
  }

  fillForm(): void {
    this.lastView = this.data2
    console.log("this.data2:", this.data2)

    //this.pathroot = this.pathroot+".Method"
   this.pathObj = this.eventService.getPathObjdyn(this.data2, this.data2.Id, this.pathroot)
    console.log(" formFill this.pathObj:", this.pathObj)
    this.obj = this.eventService.populateFormdyn(new User(), this.pathObj, this.data2.Id)
    console.log("obj here:", this.obj)
    console.log("obj.form:", this.obj.form)
    this.formCurrent = this.formBuilder.group(this.obj.form)
    console.log("formCurrent:", this.formCurrent)
    console.log("details", this.obj.detail)
    //return this.formBuilder.group(formObj.form);

    // this.formCurrent.get('REPORT_NAME').setValidators(Validators.required)
  }

  raise(): void {
    console.log("Raise")
    let eventBody = this.eventService.getEventBodydyn(this.formCurrent, this.pathObj)

    console.log("eventBody", eventBody)

    let type= this.obj.form.type[0]
    console.log("type", type)
    console.log("subject:"+this.subject+"."+ eventBody.Id)
    this.commandService.raise(this.subject+"."+ eventBody.Id,
      `${ConfigService.settings.projectCode}.${this.obj.form.aggregate[0]}.${this.obj.form.type[0]}.${this.pathObj.action}`,
      `events`,
      eventBody)
      .subscribe(data => {
        console.log("Raised:", data);
        if (!this.lastView) {
          this.lastView = data[0].data;
        } else {
          this.lastView = this.eventService.reducer(this.lastView, data[0]);
        }
        console.log("emit")
        this.eventRaised.emit(this.lastView);
        console.log("emited event")

      }
      )


  }
  notValid(): void {
    console.log("not valid")
  }
  
}


