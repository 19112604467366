import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { LookupLevel1 } from '../lookup-level1';
import { CommandService } from '../services/command.service';
import { ConfigService } from '../services/config.service';
import { EventService } from '../services/event.service';

@Component({
  selector: 'app-new-tab',
  templateUrl: './new-tab.component.html',
  styleUrls: ['./new-tab.component.scss']
})
export class NewTabComponent implements OnInit {
  @Input() tab: any
  @Input() class: any
  @Input() lookupname: string
  
  pathObj: any;
  formCurrent: FormGroup;
  working: boolean= false;
  displayFields:any= ["LEVEL1_ATTRIBUTE1"]

  constructor(
  private eventsService: EventService,
  private commandService: CommandService
) { }

  ngOnInit(): void {
   // console.log("new tab:",this.tab)
    this.pathObj = this.eventsService.getPathObj(
      this.tab,
      undefined,
      "data:"+this.tab.Id+".LEVEL1",
      "Created"
    );
   // console.log("PathObj:", this.pathObj)
    this.formCurrent = this.eventsService.populateForm(
      new LookupLevel1,
      this.pathObj,
      this.pathObj.Id
    );
    console.log("Form:", this.formCurrent)
  }

  raise() {
    this.working=true;
    let eventBody = this.eventsService.getEventBody(
      this.formCurrent,
      this.pathObj
    );
    let objectType;
    let subjectId = eventBody.path.split(".")[0].split(":")[1];
    if (eventBody.path.split(".").length === 3) {
      objectType = eventBody.path.split(".")[2].split(":")[0];
    } else if (eventBody.path.split(".").length === 2) {
      objectType = eventBody.path.split(".")[1].split(":")[0];
    }
  //  console.log("subjectId:" + subjectId);
  //  console.log("EventBody:", eventBody);
  //  console.log("objectType:", objectType);

    this.commandService
      .raise(
        `sselookups.${subjectId}`,
        `${ConfigService.settings.projectCode}.Default.${objectType}.${this.pathObj.action}`,
        `admin`,
        eventBody
      ).subscribe(
        (data) => {
          console.log("DATA", data[0]);
        //   this.object.lastView={Id:"LOOKUPS",LEVEL1:[{Id:this.pathObj.path.split(".")[1].split(":")[1],LEVEL2:this.object.lastView}]}
        //   console.log("lastview:",this.object.lastView)
        //  // this.object.lastView={Id:"LOOKUPS",LEVEL1:[this.object.lastView.LEVEL1]}
        //   console.log("lastview:"+JSON.stringify(this.object.lastView,null,2))
        //   this.object.lastView = this.eventsService.reducer(
        //     this.object.lastView,
        //     data[0]
        //   );

        //   console.log("updated Vehicle Last View:", this.object.lastView.LEVEL1[0]);

        //   let Id = this.pathObj.path.split(".")[1].split(":")[1];
        //   console.log("XXXX ID:" + Id);
        //   let rows = this.object.lastView
        //   // ["user"].filter((fil) => {
        //   //   return fil.Id === Id;
        //   // })[0];
        //   console.log("rows", rows);
        //   this.dialogRef.close({ rows: rows, lastView: this.object.lastView });
          this.working=false;
        },
        (err) => {
          console.log("failed", err);
          this.working=false;
        }
      );
  }
  close() {
    console.log("close")

  }

}
