export class BusinessEvent {
    id: string = "";
    eventType: string = "";
    subject: string = "";
    eventTime: string = "";
    data: any;
    dataVersion: string = "1.0";
    metadataVersion: string = "";
    eventTopic: string = "";
  }
  