export class User {
    Id: string =''
    label: string =''
    type: string = 'USER'
    USER_EMAIL: string = ''
    USER_FIRST_NAME: string = ''
    USER_LAST_NAME: string = ''
    USER_SUPERVISOR_NAME: string=''
    USER_TELEPHONE: string = ''
    USER_COSTCENTRE: string = ''
    USER_LOCATION: string = ''
    USER_TAG: string = ''
    USER_SUPERVISOR: string = ''
    USER_SUPERVISOR_EMAIL: string=''
    USER_ORGANISATION: string =''
    USER_BUSINESS_UNIT: string = ''
    USER_ORGANIZATION_NAME: string=''
    USER_START_DATE: string=''
    USER_DIRECTORATE: string=''
    USER_REPORTING_LEVEL_1: string=''
    USER_REPORTING_LEVEL_2: string=''
    USER_REPORTING_LEVEL_3: string=''
    USER_REPORTING_LEVEL_4: string=''
    USER_REPORTING_LEVEL_5: string=''
    USER_REPORTING_LEVEL_6: string=''
    USER_REPORTING_LEVEL_7: string=''
    USER_ADMINISTRATOR: boolean = false
    USER_BU_ADMINISTRATOR: boolean = false
    USER_GLOBAL_ADMINISTRATOR: boolean = false
}
