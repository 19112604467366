import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { User } from '../user';
import { CommandService } from '../services/command.service';
import { ErrorDialogueService } from '../services/error-dialogue.service';
import { EventService } from '../services/event.service';
import { LovService } from '../services/lov.service';
import { QueryService } from '../services/query.service';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { ConfigService } from '../services/config.service';

@Component({
  selector: 'app-user-panel',
  templateUrl: './user-panel.component.html',
  styleUrls: ['./user-panel.component.scss']
})
export class UserPanelComponent implements OnChanges {

  @Input() user;


  lastView
  formCurrent: FormGroup;
  pathObj: any;
  locations$: Observable<any>;
  locationText: string;
  owners$: Observable<any>;
  ownerText: string;
  name: string;
  frequency$: Observable<any>;
  freqText: string;
  business$: Observable<any>;
  businessText: string;
  type$: Observable<any>;
  typeText: string;
  formSubscription: Subscription;
  working: boolean = false;
  flag: boolean = true;
  @Output() eventRaised: EventEmitter<any> = new EventEmitter<any>();

  constructor(private commandService: CommandService, private eventService: EventService,
    private queryService: QueryService, private errorDialogueService: ErrorDialogueService,
    private lovService: LovService) { }

  ngOnChanges(): void {
    this.lovService.setLookups('sselookups-cv', 'LOOKUPS');
    this.setLookups();
    this.name = this.user.name;
    this.fillForm(true);
  }

  fillForm(flag): void {
    console.log("user", this.user)
    this.queryService.getAlias(ConfigService.settings.account, "user-events", this.name, flag)
      .subscribe(res => {
        console.log("res", res);
        this.user = res
        console.log("user", this.user);
        this.lastView = this.user

        let pathroot = 'data'
        this.pathObj = this.eventService.getPathObj(this.user, this.user.Id, pathroot)
        console.log("this.pathObj", this.pathObj)
        this.formCurrent = this.eventService.populateForm(new User(), this.pathObj, this.user.Id)
        this.formCurrent.controls['USER_REPORTING_LEVEL_1'].disable()
        this.formCurrent.controls['USER_REPORTING_LEVEL_2'].disable()
        this.formCurrent.controls['USER_REPORTING_LEVEL_3'].disable()
        this.formCurrent.controls['USER_REPORTING_LEVEL_4'].disable()
        this.formCurrent.controls['USER_REPORTING_LEVEL_5'].disable()
        this.formCurrent.controls['USER_REPORTING_LEVEL_6'].disable()
        this.formCurrent.controls['USER_REPORTING_LEVEL_7'].disable()
        this.formCurrent.controls['USER_DIRECTORATE'].disable()
        console.log("7", this.formCurrent.get('USER_REPORTING_LEVEL_7').value)
        this.handleFormChanges();

        this.working = false
      }, (err) => {
        this.working = false;
        console.error("Failed", err);
      })




  }

  raise() {
    let eventBody = this.eventService.getEventBody(this.formCurrent, this.pathObj)
    console.log("user eventBody:", eventBody)
    console.log("user email:"), this.user.USER_EMAIL
    this.commandService.raise(`user.` + eventBody.Id,
      `${ConfigService.settings.projectCode}.SEARS/User.${this.formCurrent.get('type').value}.${this.pathObj.action}`,
      `events`,
      eventBody)
      .subscribe(data => {
        console.log("Raised:", data);
        if (!this.lastView) {
          this.lastView = data[0].data;
        } else {
          this.lastView = this.eventService.reducer(this.lastView, data[0]);
        }
        this.eventRaised.emit();

      }
      )
  }

  setLookups = () => {
    this.locations$ = this.lovService.location$;
    this.owners$ = this.lovService.owners$;
    this.frequency$ = this.lovService.frequency$;
    this.business$ = this.lovService.business$;
    this.type$ = this.lovService.type$;

  };

  handleFormChanges = () => {
    this.formSubscription = this.formCurrent.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(200))
      .subscribe((changes) => {

        this.locationText = changes['AUDIT_LOCATION'];
        this.ownerText = changes['AUDIT_TEAM_MANAGER'];
        this.freqText = changes['AUDIT_FREQUENCY'];
        this.businessText = changes['AUDIT_BUSINESS_AREA'];
        this.typeText = changes['AUDIT_TYPE'];
      });
  };
  ngOnDestroy(): void {
    this.formSubscription.unsubscribe();
  }

}
