import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommandService } from '../services/command.service';
import { ConfigService } from '../services/config.service';
import { EventService } from '../services/event.service';

@Component({
  selector: 'app-dialog-box',
  templateUrl: './dialog-box.component.html',
  styleUrls: ['./dialog-box.component.scss']
})
export class DialogBoxComponent implements OnInit {

  action: string;
  local_data: any;
  object;
  pathObj: any;
  formCurrent: FormGroup;
  working: boolean= false;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private eventsService: EventService,
    private commandService: CommandService
  ) {
    console.log("data", data);

    this.object = { ...data };
    console.log("currentView", this.object.lastView);
    this.action = this.object.action;
  }

  ngOnInit() {
    console.log("user panel", this.object);
    this.pathObj = this.eventsService.getPathObj(
      this.object.instance,
      this.object.instance.Id,
      this.object.pathroot,
      this.object.action
    );
    console.log("PathObj:", this.pathObj)
    this.formCurrent = this.eventsService.populateForm(
      this.object.class,
      this.pathObj,
      this.pathObj.Id
    );
    console.log("form:", this.formCurrent)
    console.log("popup patchObj", this.pathObj);
  }

  raise() {
    this.working=true;
    let eventBody = this.eventsService.getEventBody(
      this.formCurrent,
      this.pathObj
    );
    let objectType;
    let subjectId = eventBody.path.split(".")[0].split(":")[1];
    if (eventBody.path.split(".").length === 3) {
      objectType = eventBody.path.split(".")[2].split(":")[0];
    } else if (eventBody.path.split(".").length === 2) {
      objectType = eventBody.path.split(".")[1].split(":")[0];
    }
    console.log("subjectId:" + subjectId);
    console.log("EventBody:", eventBody);
    console.log("objectType:", objectType);

    this.commandService
      .raise(
        `sselookups.${subjectId}`,
        `${ConfigService.settings.projectCode}.Default.${objectType}.${this.pathObj.action}`,
        `admin`,
        eventBody
      ).subscribe(
        (data) => {
          console.log("DATA", data[0]);
          this.object.lastView={Id:"LOOKUPS",LEVEL1:[{Id:this.pathObj.path.split(".")[1].split(":")[1],LEVEL2:this.object.lastView}]}
          console.log("lastview:",this.object.lastView)
         // this.object.lastView={Id:"LOOKUPS",LEVEL1:[this.object.lastView.LEVEL1]}
          console.log("lastview:"+JSON.stringify(this.object.lastView,null,2))
          this.object.lastView = this.eventsService.reducer(
            this.object.lastView,
            data[0]
          );

          console.log("updated Vehicle Last View:", this.object.lastView.LEVEL1[0]);

          let Id = this.pathObj.path.split(".")[1].split(":")[1];
          console.log("XXXX ID:" + Id);
          let rows = this.object.lastView
          // ["user"].filter((fil) => {
          //   return fil.Id === Id;
          // })[0];
          console.log("rows", rows);
          this.dialogRef.close({ rows: rows, lastView: this.object.lastView });
          this.working=false;
        },
        (err) => {
          console.log("failed", err);
          this.working=false;
        }
      );
  }
  close() {
    this.dialogRef.close({ rows: { LEVEL2_ATTRIBUTE2: "car" } });
  }

  doAction() {
    this.dialogRef.close({ event: this.action, data: this.local_data });
  }

}
