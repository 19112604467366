import { v4 as uuid } from "uuid";
import { Attribute } from "./attribute";
export class Security {
    Id: Attribute = {ATTRIBUTE_NAME:"Id",ATTRIBUTE_PROMPT:"Id",ATTRIBUTE_REQUIRED:true, ATTRIBUTE_VALUE:uuid(),ATTRIBUTE_TYPE:'hidden'}
    type: Attribute = {ATTRIBUTE_NAME:"type",ATTRIBUTE_PROMPT:"type",ATTRIBUTE_REQUIRED:true, ATTRIBUTE_VALUE:'security', ATTRIBUTE_TYPE:'hidden'}
    aggregate: Attribute = {ATTRIBUTE_NAME:"aggregate",ATTRIBUTE_PROMPT:"aggregate",ATTRIBUTE_REQUIRED:true, ATTRIBUTE_VALUE:'SEARS/Security', ATTRIBUTE_TYPE:'hidden'}
    SECURITY_AGGREGATE: Attribute = {ATTRIBUTE_NAME:"SECURITY_AGGREGATE",ATTRIBUTE_PROMPT:"Aggregate name",ATTRIBUTE_REQUIRED:true, ATTRIBUTE_VALUE:''}
   CHILDREN:  Array<Rule> 
}

export class Rule {
    Id: Attribute = {ATTRIBUTE_NAME:"Id",ATTRIBUTE_PROMPT:"Id",ATTRIBUTE_REQUIRED:true, ATTRIBUTE_VALUE:uuid(),ATTRIBUTE_TYPE:'hidden'}
    type: Attribute = {ATTRIBUTE_NAME:"type",ATTRIBUTE_PROMPT:"type",ATTRIBUTE_REQUIRED:true, ATTRIBUTE_VALUE:'rule', ATTRIBUTE_TYPE:'hidden'}
    aggregate: Attribute = {ATTRIBUTE_NAME:"aggregate",ATTRIBUTE_PROMPT:"aggregate",ATTRIBUTE_REQUIRED:true, ATTRIBUTE_VALUE:'SEARS/Security', ATTRIBUTE_TYPE:'hidden'}
    RULE_METHOD: Attribute = {ATTRIBUTE_NAME:"RULE_METHOD",ATTRIBUTE_PROMPT:"Method",ATTRIBUTE_REQUIRED:true, ATTRIBUTE_VALUE:''}
  //  CHILDREN: Array<User>

}

export class User {
    Id: Attribute = {ATTRIBUTE_NAME:"Id",ATTRIBUTE_PROMPT:"Id",ATTRIBUTE_REQUIRED:true, ATTRIBUTE_VALUE:uuid(),ATTRIBUTE_TYPE:'hidden'}
    type: Attribute = {ATTRIBUTE_NAME:"type",ATTRIBUTE_PROMPT:"type",ATTRIBUTE_REQUIRED:true, ATTRIBUTE_VALUE:'user', ATTRIBUTE_TYPE:'hidden'}
    aggregate: Attribute = {ATTRIBUTE_NAME:"aggregate",ATTRIBUTE_PROMPT:"aggregate",ATTRIBUTE_REQUIRED:true, ATTRIBUTE_VALUE:'SEARS/Security', ATTRIBUTE_TYPE:'hidden'}
    USER_ID: Attribute = {ATTRIBUTE_NAME:"USER_ID",ATTRIBUTE_PROMPT:"User Id",ATTRIBUTE_REQUIRED:true, ATTRIBUTE_VALUE:''}
    USER_EMAIL: Attribute = {ATTRIBUTE_NAME:"USER_EMAIL",ATTRIBUTE_PROMPT:"User Email",ATTRIBUTE_REQUIRED:true, ATTRIBUTE_VALUE:''}

}
