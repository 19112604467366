<mat-card class=card *ngIf="formCurrent">
    <form [formGroup]="formCurrent" class="column" *ngIf="formCurrent" class=column>
        <div *ngFor="let attribute of obj.detail">
        <mat-form-field *ngIf="attribute.ATTRIBUTE_TYPE!='hidden'" appearance="outline">
            <mat-label>{{attribute.ATTRIBUTE_PROMPT}}</mat-label>
            <input matInput formControlName={{attribute.ATTRIBUTE_NAME}} type={{attribute.ATTRIBUTE_TYPE}}>

        </mat-form-field>
    </div>
        <!-- <mat-form-field appearance="outline">
            
            <input matInput formControlName="REPORT_TITLE" />
        </mat-form-field>
        <mat-form-field appearance="outline">
            
            <input matInput formControlName="REPORT_KEY" />
        </mat-form-field> -->
        {{formCurrent.valid}}
        <div *ngIf="formCurrent">
            <button mat-raised-button type=submit color=primary [disabled]="!formCurrent.dirty||readonly"
                (click)=(formCurrent.valid?raise():notValid())>Save</button>

        </div>
    </form>
</mat-card>