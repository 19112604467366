<mat-tab-group>
    <mat-tab label="Users"><app-users></app-users></mat-tab>
    <mat-tab label="Audit Lookups"><app-lookups lookupname="AUDITLOOKUP"></app-lookups></mat-tab>
    <mat-tab label="SORS Lookups"><app-lookups lookupname="SORSLOOKUP"></app-lookups></mat-tab>
    <mat-tab label="SEARS Lookups"><app-lookups lookupname="LOOKUPS"></app-lookups></mat-tab>
    <mat-tab label="30Mins Lookups"><app-lookups lookupname="30MINSLOOKUP"></app-lookups></mat-tab>
    <mat-tab label="Settings"></mat-tab>
    <mat-tab label="Security"><app-security-tab ></app-security-tab></mat-tab>
    <mat-tab label="Audit Dashboards"><app-dashboard dashboardname="Audit"></app-dashboard></mat-tab>
      <mat-tab label="SORS Dashboards"><app-dashboard dashboardname="SORS"></app-dashboard></mat-tab>
      <mat-tab label="SEARS Dashboards"><app-dashboard dashboardname="SEARS"></app-dashboard></mat-tab>
      <mat-tab label="30Mins Dashboards"><app-dashboard dashboardname="30Mins"></app-dashboard></mat-tab>
</mat-tab-group>
