import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Report } from '../report';
import { CommandService } from '../services/command.service';
import { ConfigService } from '../services/config.service';
import { EventService } from '../services/event.service';
import { QueryService } from '../services/query.service';

@Component({
  selector: 'app-dashboard-panel',
  templateUrl: './dashboard-panel.component.html',
  styleUrls: ['./dashboard-panel.component.scss']
})
export class DashboardPanelComponent implements OnInit {

  
  lastView: Report
  formCurrent: FormGroup;
  pathObj
  readonly: boolean = false
  obj
  @Input() dashboard

  constructor(private commandService: CommandService, private eventService: EventService,
    private queryService: QueryService, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.fillForm()
  }

  fillForm(): void {
    this.lastView = this.dashboard

    let pathroot = 'data'
    this.pathObj = this.eventService.getPathObj(this.dashboard, this.dashboard.Id, pathroot)
    console.log("this.pathObj:", this.pathObj)
    this.obj = this.eventService.populateFormdyn(new Report(), this.pathObj, this.dashboard.Id)
    console.log("obj here:", this.obj)
    console.log("obj.form:", this.obj.form)
    this.formCurrent = this.formBuilder.group(this.obj.form)
    console.log("formCurrent:", this.formCurrent)
    console.log("details", this.obj.detail)
    //return this.formBuilder.group(formObj.form);

    // this.formCurrent.get('REPORT_NAME').setValidators(Validators.required)
  }

  raise(): void {
    console.log("Raise")
    let eventBody = this.eventService.getEventBody(this.formCurrent, this.pathObj)

    console.log("Object", this.obj)

    let type= this.obj.form.type[0]
    console.log("type:"+type)
    //   eventBody['subject']="Audit: "+eventBody.Id
    //   eventBody['url']="http://localhost:4200/audit/"+eventBody.Id
    //   eventBody['urllabel'] = "Open Audit "+eventBody.Id
    //   console.log('AUDIT_PLANNED_DATE:'+eventBody.AUDIT_PLANNED_DATE)
    //   let checkdate=(eventBody.AUDIT_PLANNED_DATE?moment(eventBody.AUDIT_PLANNED_DATE,'YYYY-MM-DD'):moment(this.audit.AUDIT_PLANNED_DATE,'YYYY-MM-DD'))
    //   eventBody['AUDIT_FISCALYEAR']=(moment(checkdate).quarter()===1?moment(checkdate).subtract(1,"year").format("YYYY"):moment(checkdate).format("YYYY"))
    //   eventBody['AUDIT_FISCALQUARTER']=(moment(checkdate).quarter()===1?moment(checkdate).subtract(1,"year").format("YYYY")+"/4":moment(checkdate).format("YYYY")+"/"+(moment(checkdate).quarter()-1))
    //   console.log('AUDIT_FISCALYEAR:'+eventBody.AUDIT_FISCALYEAR)
    //   console.log('AUDIT_FISCALQUARTER:'+eventBody.AUDIT_FISCALQUARTER)
    //   eventBody['AUDIT_TITLE']=(eventBody.AUDIT_LEVEL?eventBody.AUDIT_LEVEL.substr(0,2):this.audit.AUDIT_LEVEL.substr(0,2))+"."
    //   +(eventBody.AUDIT_TYPE?eventBody.AUDIT_TYPE:this.audit.AUDIT_TYPE)+"."
    //   +(eventBody.AUDIT_SUBJECT?eventBody.AUDIT_SUBJECT:this.audit.AUDIT_SUBJECT)+"."
    //   +(eventBody.AUDIT_PLANNED_DATE?checkdate.format("DD/MM/YYYY"):moment(this.audit.AUDIT_PLANNED_DATE,'YYYY/MM/DD').format("DD/MM/YYYY"))
    //   if(!this.audit.AUDIT_DATE)
    //     eventBody['AUDIT_DATE']=moment().unix()
    // //  eventBody.concat(eventBody, {subject: "My Subject"});
    //   console.log("user eventBody:", eventBody)
    //console.log("user data:", this.user.data)
    //console.log("user email:"), this.user.USER_EMAIL
    //console.log("$this.obj.form.type[0]",`${this.obj}`.form.type[0]`)
    this.commandService.raise(this.obj.form.type[0]+"."+ eventBody.Id,
      `${ConfigService.settings.projectCode}.${this.obj.form.aggregate[0]}.${this.obj.form.type[0]}.${this.pathObj.action}`,
      `events`,
      eventBody)
      .subscribe(data => {
        console.log("Raised:", data);
        if (!this.lastView) {
          this.lastView = data[0].data;
        } else {
          this.lastView = this.eventService.reducer(this.lastView, data[0]);
        }
        console.log("emit")
        //this.eventRaised.emit();

      }
      )

  }

  notValid(): void {
    console.log("not valid")
  }

}
