<mat-card class=card *ngIf="formCurrent">
    <form [formGroup]="formCurrent" class="column" *ngIf="formCurrent" class=column>
        <div *ngFor="let attribute of obj.detail">
            <mat-form-field *ngIf="attribute.ATTRIBUTE_TYPE!='hidden'" appearance="outline">
                <mat-label>{{attribute.ATTRIBUTE_PROMPT}}</mat-label>
                <input matInput formControlName={{attribute.ATTRIBUTE_NAME}} type={{attribute.ATTRIBUTE_TYPE}}>

            </mat-form-field>
        </div>
        <div *ngIf=data.children>
           

            <div *ngFor="let child of data.children">

                <app-rule-user [data2]=child subject="security" [blankChild]="blank2Child" [pathroot]=childpathroot>
                </app-rule-user>

            </div>
        </div>
        <div *ngIf=data.user>


            <div *ngFor="let child of data.user">
          
                <app-rule-user [data2]=child subject="security" [blankChild]="blank2Child" [pathroot]=childpathroot>
                </app-rule-user>

            </div>
        </div>
        <button mat-raised-button color=primary [disabled]="!formCurrent.valid" (click)=addChild()>AddChild</button>

        <div *ngIf="formCurrent">
            <button mat-raised-button type=submit color=primary [disabled]="!formCurrent.dirty||readonly"
                (click)=(formCurrent.valid?raise():notValid())>Save</button>

        </div>
    </form>
</mat-card>