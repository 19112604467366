<mat-card class=card *ngIf=object >
    <mat-progress-bar *ngIf="working" mode="indeterminate"></mat-progress-bar>
    <div *ngIf="pathObj.action==='Deleted'">Confirm Deletion</div>
    <form [formGroup]="formCurrent" class="column" *ngIf="formCurrent">
        <!-- <mat-form-field><input matInput type=text placeholder="username*" formControlName="email" autocomplete="off"></mat-form-field>
        <mat-label  class=select>Role*</mat-label>

        <mat-select matInput formControlName="role" style="margin-top:3px">

            <mat-option  value="User">User</mat-option>
            <mat-option  value="Viewer">Viewer</mat-option>
        </mat-select> -->
        <div class=rowline>
         <div *ngFor="let attribute of object.displayColumns" class=rowline>
            
            <mat-form-field>
                <input matInput type=text [placeholder]=attribute [formControlName]=attribute autocomplete="off">
            </mat-form-field>

            <!-- <mat-form-field class=rowline>
                <input matInput type=text placeholder="LEVEL2_ATTRIBUTE3" formControlName="LEVEL2_ATTRIBUTE3" autocomplete="off">
            </mat-form-field> -->
        </div>
            
        </div> 
      
    </form>
    
    <mat-card-actions class=buttons>

        <button *ngIf="pathObj.action==='Deleted'" mat-icon-button type="submit" [disabled]="!formCurrent.valid"
            color="warn" (click)="raise()">
            <mat-icon>cancel</mat-icon>
        </button>
        <button *ngIf="pathObj.action!='Deleted'" mat-icon-button type="submit" 
            color="primary" (click)="close()">
            <mat-icon>cancel</mat-icon>
        </button>
        <button *ngIf="pathObj.action!='Deleted'" mat-icon-button type="submit" [disabled]="!formCurrent.dirty"
            color="primary" (click)="raise()">
            <mat-icon>check</mat-icon>
        </button>
    </mat-card-actions>

</mat-card>
