<mat-progress-bar *ngIf="working" mode="indeterminate" color="primary"></mat-progress-bar>


<form [formGroup]="filterForm" *ngIf="filterForm" class=column>
    
    <mat-form-field>
        <mat-label>Level</mat-label>
        <input matInput formControlName="email"  />
        

    </mat-form-field>
    
</form>
<button mat-icon-button (click)="refresh()" color="primary"><mat-icon>refresh</mat-icon></button>
<div class=row>
    <div class="column20">
        <div *ngFor="let user of users">
            <button mat-button (click)="selecteduser=user" >{{user.name}}</button>
        </div>

    </div>
    <div *ngIf="selecteduser">
        <app-user-panel [user]=selecteduser></app-user-panel>
    </div>
</div>



