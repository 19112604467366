import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ConfigService } from '../services/config.service';
import { QueryService } from '../services/query.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  users: Array<any>
  selecteduser
  working: boolean = false
  filterForm: FormGroup

  constructor(private userService: UserService, private queryService: QueryService) { }

  ngOnInit(): void {
    this.filterform()
  }

  filterform() {
    this.filterForm = new FormGroup({
      email: new FormControl(),
    });

  }

  refresh() {
    console.log("refresh users", this.filterForm)
    let searchmail = this.filterForm.get('email').value
    this.working = true
    this.queryService.getList(ConfigService.settings.account, "user-events", searchmail)
      .subscribe(res => {
        console.log("res", res);
        this.users = res.blobs
        console.log("users", this.users);
        this.working = false
      }, (err) => {
        this.working = false;
        console.error("Failed", err);
      })
  }

}
