import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { LookupLevel0, LookupLevel1, LookupLevel2 } from '../lookups';
import { ConfigService } from './config.service';
import { QueryService } from './query.service';

export class LookUpContainer {
  constructor(data: any) {
    this.bhSub.next(data);
  }
  private readonly bhSub = new BehaviorSubject([]);
  readonly obs = this.bhSub.asObservable();
}

@Injectable({
  providedIn: 'root'
})
export class LovService {

  constructor(private http: HttpClient, private queryService: QueryService) {}
  lookup: any = {};
  private readonly _alllookups: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  readonly alllookups$: Observable<any> = this._alllookups.asObservable();

  set alllovs(lovs: any) {
    this._alllookups.next([lovs]);
  }
  get alllovs() {
    return this._alllookups.getValue();
  }

  private readonly ALLKEYWORDS = new BehaviorSubject<LookupLevel2[]>([]);
  readonly allkeywords$ = this.ALLKEYWORDS.asObservable();

  get allKeywords() {
    return this.ALLKEYWORDS.getValue();
  }

  private readonly LOCATION: BehaviorSubject<LookupLevel2[]> = new BehaviorSubject<LookupLevel2[]>([]);
  readonly location$: Observable<LookupLevel2[]> = this.LOCATION.asObservable();



  get location() {
    return this.LOCATION.getValue();
  }

  private readonly OWNERS: BehaviorSubject<LookupLevel2[]> = new BehaviorSubject<LookupLevel2[]>([]);
  readonly owners$: Observable<LookupLevel2[]> = this.OWNERS.asObservable();



  get owners() {
    return this.OWNERS.getValue();
  }
  private readonly FREQUENCY: BehaviorSubject<LookupLevel2[]> = new BehaviorSubject<LookupLevel2[]>([]);
  readonly frequency$: Observable<LookupLevel2[]> = this.FREQUENCY.asObservable();
  get frequency() {
    return this.FREQUENCY.getValue();
  }

  private readonly BUSINESS_UNIT: BehaviorSubject<LookupLevel2[]> = new BehaviorSubject<LookupLevel2[]>([]);
  readonly business$: Observable<LookupLevel2[]> = this.BUSINESS_UNIT.asObservable();
  get business() {
    return this.BUSINESS_UNIT.getValue();
  }

  private readonly AUDIT_TYPE: BehaviorSubject<LookupLevel2[]> = new BehaviorSubject<LookupLevel2[]>([]);
  readonly type$: Observable<LookupLevel2[]> = this.AUDIT_TYPE.asObservable();
  get type() {
    return this.AUDIT_TYPE.getValue();
  }

  private readonly CATAGORIES: BehaviorSubject<LookupLevel2[]> = new BehaviorSubject<LookupLevel2[]>([]);
  readonly catagories$: Observable<LookupLevel2[]> = this.CATAGORIES.asObservable();

  set catagories(arr: any) {
    this.CATAGORIES.next([...this.CATAGORIES.getValue(), arr]);
  }

  list = ['PEOPLE', 'ENVIRONMENT', 'ASSET', 'REPUTATION'];
  keywordList = ['INJURYKEYWORDS', 'ASSETKEYWORDS', 'RTCKEYWORDS', 'ENVKEYWORDS', 'REPUTATIONKEYWORDS', 'INJURYLIST'];
  others = [


    'LOCATION',
    'OWNERS',
    'FREQUENCY',
    'BUSINESS_UNIT',
    'AUDIT_TYPE'


  ];
  // getList = (container: string): Observable<any> => {
  //   return this.http.get('https://rodevTest.azurewebsites.net/api/getList', {
  //     params: new HttpParams().set('account', 'rodevshe').set('container', container),
  //   });
  // };

  setLookups = (container: string, blob: string) => {
    this.http
      .get('getBlob', {
        params: new HttpParams().set('account', ConfigService.settings.account).set('container', container).set('blob', blob),
      })
      .subscribe((res: any) => {
        console.log("res", res)
        this.alllovs = res.LEVEL1;
        res.data.LEVEL1.forEach((lookups: LookupLevel1) => {
          this.lookup[lookups.LEVEL1_ATTRIBUTE1] = new LookUpContainer(lookups.LEVEL2);
          if (this.list.includes(lookups.LEVEL1_ATTRIBUTE1)) {
            this.catagories = lookups;
            return;
          }
          if (this.others.includes(lookups.LEVEL1_ATTRIBUTE1)) {
            console.log(lookups.LEVEL1_ATTRIBUTE1)
            this[lookups.LEVEL1_ATTRIBUTE1].next(lookups.LEVEL2);
            console.log (this[lookups.LEVEL1_ATTRIBUTE1])
          }
        });
      });
    }
}

