import { Component, OnInit } from '@angular/core';
import { Rule, Security } from '../security';
import { ConfigService } from '../services/config.service';
import { QueryService } from '../services/query.service';

@Component({
  selector: 'app-security-tab',
  templateUrl: './security-tab.component.html',
  styleUrls: ['./security-tab.component.scss']
})
export class SecurityTabComponent implements OnInit {

  
  security: Array<Security> =[]
  currentView
  newChild: Rule= new Rule()

  constructor(private queryService: QueryService) { }

  ngOnInit(): void {
    this.queryService.getAndBuild(`${ConfigService.settings.projectCode}`, 'security-events', 'security', true)
    .subscribe(data => {console.log("XXXXX CurretViwe:", data.rule);this.security=data.rule
    },(err) =>{console.error("security not found",err)})

  }

  clickAdd() {
    console.log("click Add")
    this.security.push(new Security())
    
  }

  backfrom(event) {
    console.log("back from:",event)
  }

}
