import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Rule, Security, User } from '../security';
import { CommandService } from '../services/command.service';
import { ConfigService } from '../services/config.service';
import { EventService } from '../services/event.service';
import { QueryService } from '../services/query.service';

@Component({
  selector: 'app-security-panel',
  templateUrl: './security-panel.component.html',
  styleUrls: ['./security-panel.component.scss']
})
export class SecurityPanelComponent implements OnInit {

  
  @Input() sercuritypanel
  securityrules

  lastView: Security
  formCurrent: FormGroup;
  blankChild: Rule=new Rule()
  blank2Child: User=new User()
  childPath
  pathObj
  readonly: boolean = false
  @Input() newChild
  obj
  @Output() eventRaised: EventEmitter<any> = new EventEmitter<any>();


  constructor(private commandService: CommandService, private eventService: EventService,
    private queryService: QueryService, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    console.log("newChild:",this.newChild)
    this.fillForm()
    console.log("OOOO pathObj", this.pathObj)
  }

  fillForm(): void {
    this.lastView= this.sercuritypanel
    console.log("sercuritypanel:", this.sercuritypanel)

    let pathroot = 'data:security.rule'
   this.pathObj = this.eventService.getPathObjdyn(this.sercuritypanel, this.sercuritypanel.Id, pathroot)
    console.log(" formFill this.pathObj:", this.pathObj)
    this.obj = this.eventService.populateFormdyn(new Security(), this.pathObj, this.sercuritypanel.Id)
    console.log("obj here:", this.obj)
    console.log("obj.form:", this.obj.form)
    this.formCurrent = this.formBuilder.group(this.obj.form)
    console.log("formCurrent:", this.formCurrent)
    console.log("details", this.obj.detail)
    this.childPath=this.sercuritypanel.Method
    //return this.formBuilder.group(formObj.form);

    // this.formCurrent.get('REPORT_NAME').setValidators(Validators.required)
  }

  raise(): void {
    console.log("Raise")
    let eventBody = this.eventService.getEventBodydyn(this.formCurrent, this.pathObj)

    console.log("eventBody", eventBody)

    let type= this.obj.form.type[0]
    console.log("type", type)
    console.log("subject:"+type+"."+ eventBody.Id)
    this.commandService.raise(type+"."+ eventBody.Id,
      `${ConfigService.settings.projectCode}.${this.obj.form.aggregate[0]}.${this.obj.form.type[0]}.${this.pathObj.action}`,
      `events`,
      eventBody)
      .subscribe(data => {
        console.log("Raised:", data);
        if (!this.lastView) {
          this.lastView = data[0].data;
        } else {
          this.lastView = this.eventService.reducer(this.lastView, data[0]);
        }
        console.log("emit")
        this.eventRaised.emit(this.lastView);
        console.log("emited event")

      }
      )


  }
  notValid(): void {
    console.log("not valid")
  }

  addChild() : void {
    
      
    
      console.log("child", this.sercuritypanel)
      if (!this.childPath) {
        this.childPath=[this.newChild]
      }
      else {
        this.childPath.push(this.newChild)
      }
      console.log("this.sercuritypanel",this.sercuritypanel)
  
    
  }
}
