export class Lookup {
    Id: string = '';
    LEVEL2_ATTRIBUTE2: string='';
    LEVEL2_ATTRIBUTE3: string='';
    LEVEL2_ATTRIBUTE4: string='';
    LEVEL2_ATTRIBUTE5: string='';
    LEVEL2_ATTRIBUTE6: string='';
    LEVEL2_ATTRIBUTE7: string='';
    LEVEL2_ATTRIBUTE8: string='';
    LEVEL2_ATTRIBUTE9: string='';	

}
