import { Component, Input, OnInit } from '@angular/core';
import { Report } from '../report';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  @Input() dashboardname
  dashboards: Array<Report> =[]

  constructor() { }

  ngOnInit(): void {
  }

  clickAdd() {
    console.log("click Add")
    this.dashboards.push(new Report())
    
  }

}
