import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { HomeComponent } from './home/home.component';
import { LoginFailedComponent } from './login-failed.component';
import { LookupsComponent } from './lookups/lookups.component';


const routes: Routes = [{

  path: '',
  component: HomeComponent,
  canActivate: [MsalGuard]
},
  {
    path: 'login-failed',
    pathMatch: 'full',
    component: LoginFailedComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
