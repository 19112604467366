import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../services/config.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  user: any;
 

  constructor(private userService:UserService) { }


  ngOnInit(): void {
    this.userService.getUserInfo().subscribe(res => this.user=res)
    console.log(this.user)
  }

}
